"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileGeoStyleString(branding) {
    const { geo = {} } = branding;
    const { bg, maxHeight, radius, margin: { top: marginTop } = {}, padding: { top: paddingTop, bottom: paddingBottom, left: paddingLeft, right: paddingRight } = {}, shadow, scroll: { show } = {}, item: { radius: itemRadius, hoverBg, text: { marginLeft: textMarginLeft } = {} } = {} } = geo;
    return `
    {
        ${bg ? `--suggest-field-background-color: ${bg};` : ''}
        ${maxHeight ? `--suggest-field-max-height: ${maxHeight};` : ''}
        ${radius ? `--suggest-field-border-radius: ${radius};` : ''}
        
        ${marginTop ? `--suggest-field-margin-top: ${marginTop};` : ''}
        
        ${paddingTop ? `--suggest-field-padding-top: ${paddingTop};` : ''}
        ${paddingBottom ? `--suggest-field-padding-bottom: ${paddingBottom};` : ''}
        ${paddingLeft ? `--suggest-field-padding-left: ${paddingLeft};` : ''}
        ${paddingRight ? `--suggest-field-padding-right: ${paddingRight};` : ''}

        ${shadow ? `--suggest-field-shadow: ${shadow};` : ''}

        ${show ? `--suggest-field-overflow-y: auto;` : ''}

        ${itemRadius ? `--suggest-field-item-border-radius: ${itemRadius};` : ''}
        ${hoverBg ? `--suggest-field-item-hover-background-color: ${hoverBg};` : ''}
        ${textMarginLeft ? `--suggest-field-item-margin-left: ${textMarginLeft};` : ''}
    }
    `;
}
exports.default = compileGeoStyleString;
