"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
function getQuestionTableStyles(props) {
    const { columns, id, rowBg, columnBg, rowBgMobile } = props;
    return (0, react_1.css) `
        .question-table__columns-header {
            grid-area: header;
            display: flex;
            font-size: 16px;
            color: #2f3747;
            gap: 6px;
            position: sticky;
            top: 0px;
            z-index: 100;
            padding: 18px 0px;
            background: white;
        }

        .question-table__columns-header_isDemo {
            background: transparent;
            position: static;
        }

        .question-table__column-title {
            flex: 1 0 0px;
            text-align: center;
            min-width: 0;
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 100%;
            word-break: break-word;
            padding: 10px;
        }

        .question-table__column-title_withoutImage {
            justify-content: start;
        }

        .question-table__column-title_withoutLabel {
            justify-content: end;
        }

        .question-table__filler {
            width: 180px;
        }

        .question-table__rows {
            grid-area: row;
            display: grid;
            grid-gap: 12px;
        }

        .question-table__rows .choice__option {
            margin-top: 0 !important;
            display: flex;
            justify-content: center;
            padding: 6px 18px !important;
            margin-inline-end: 0px;
        }

        .question-table__rows .choice__option-label {
            justify-content: center !important;
            align-items: center !important;
            min-height: auto !important;
        }

        .question-table .choice__option-control {
            font-size: 9px !important;
            -webkit-margin-end: 0 !important;
            margin-inline-end: 0 !important;
            top: 0 !important;
            border: 1px solid var(--view-default-fill-color-action) !important;
        }

        .question-table .choice__option-control.choice__option-control_multiple_yes {
            border-radius: 5px !important;
        }

        .question-table__row-option {
            background: var(--table-option-background-color);
            border-radius: var(--table-option-border-radius);
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
        }
        .question-table__row-container {
            height: 100%;
        }

        .question-table__row-option:hover {
            background: var(--table-option-background-color-hover);
            cursor: pointer;
        }

        .question-table__row-option.question-table__row-option_checked {
            background: var(--table-option-background-color-selected);
        }

        .question-table__row-option.question-table__row-option_checked:hover {
            background: var(--table-option-background-color-selected-hover);
        }

        .question-table__row-${id}_isHidden {
            background-color: rgba(246, 248, 251, 1);
            padding: 10px 10px;
            border-radius: 12px;
            position: relative;
            width: 100%;
            margin-inline: -10px;
        }

        .question-table__column-title_isHidden {
            background-color: rgba(246, 248, 251, 1);
            border-radius: 12px;
        }

        .question-table__columns-header_displayHidden {
            background-color: rgba(246, 248, 251, 1);
        }

        .question-table_rowBadge,
        .question-table_columnBadge {
            margin-bottom: 10px;
        }

        .question_hidden .question-table__row-${id}_isHidden {
            padding: 0px 10px;
        }

        .question-table__row-${id} {
            display: grid;
            ${`grid-template-columns: 180px repeat(${columns.length}, 1fr)`};
            font-size: 16px;
            color: #2f3747;
            grid-gap: 6px;
            align-items: center;
            flex-wrap: wrap;
        }

        .question__content_error_yes
            .question-table__row-${id}.question-table__row-${id}_noSelection
            .question-table__row-option {
            background: var(--table-option-error-background-color);
        }
        .question__content_error_yes
            .question-table__row-${id}.question-table__row-${id}_noSelection
            .question-table__row-option:hover {
            background: var(--table-option-error-background-color-hover);
        }

        .question-table__modal img {
            max-height: 80vh;
            max-width: 80vw;
            border-radius: 4px;
            display: block;
        }

        .question-table__column-image,
        .question-table__row-image {
            width: 100%;
            height: auto;
            aspect-ratio: 1;
            object-fit: contain;
            border-radius: 14px;
            box-sizing: border-box;
            border: 1px solid rgba(165, 177, 202, 0.3);
            margin-top: 10px;
        }

        .question-table__image-container {
            position: relative;
        }

        .question-table__image-container_zoomable {
            cursor: pointer;
        }

        .question-table__image-container_zoomable:hover::after {
            content: '';
            position: absolute;
            top: 20px;
            right: 10px;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuMjUgMTIuNUMyLjc5ODIyIDEyLjUgMCA5LjcwMTc4IDAgNi4yNUMwIDIuNzk4MjIgMi43OTgyMiAwIDYuMjUgMEM5LjcwMTc4IDAgMTIuNSAyLjc5ODIyIDEyLjUgNi4yNUMxMi41IDcuNjE0OTcgMTIuMDYyNCA4Ljg3Nzc1IDExLjMxOTkgOS45MDU3TDE1LjIwNzEgMTMuNzkyOUMxNS41OTc2IDE0LjE4MzQgMTUuNTk3NiAxNC44MTY2IDE1LjIwNzEgMTUuMjA3MUMxNC44MTY2IDE1LjU5NzYgMTQuMTgzNCAxNS41OTc2IDEzLjc5MjkgMTUuMjA3MUw5LjkwNTcgMTEuMzE5OUM4Ljg3Nzc1IDEyLjA2MjQgNy42MTQ5NyAxMi41IDYuMjUgMTIuNVpNNi4yNSAxMC41QzguNTk3MjEgMTAuNSAxMC41IDguNTk3MjEgMTAuNSA2LjI1QzEwLjUgMy45MDI3OSA4LjU5NzIxIDIgNi4yNSAyQzMuOTAyNzkgMiAyIDMuOTAyNzkgMiA2LjI1QzIgOC41OTcyMSAzLjkwMjc5IDEwLjUgNi4yNSAxMC41WiIgZmlsbD0iIzJGMzc0NyIvPgo8L3N2Zz4K');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 15px;
            background-color: #f6f8fb;
            border-radius: 12px;
            width: 36px;
            height: 36px;
        }

        .question-table__modal-image {
            position: relative;
            background-color: white;
            border-radius: 4px;
        }

        .question-table__modal-image::after {
            content: '';
            position: absolute;
            top: 0;
            right: -32px;
            background-image: url('data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iNjRweCIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+Cg08ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoNPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cg08ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+IDxwYXRoIGQ9Ik0xOSA1TDUgMTlNNS4wMDAwMSA1TDE5IDE5IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4gPC9nPgoNPC9zdmc+');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 25px;
            width: 20px;
            height: 20px;
        }

        .question-table__column-title-mobile {
            display: none;
        }

        .question-table__label-text {
            word-break: break-word;
        }
        .question-table__label-text .markdown p,
        .question-table__row-header-text .markdown p,
        .question-table__column-title-label .markdown p {
            color: var(--text-color) !important;
            line-height: inherit !important;
            font-size: 16px !important;
            margin: 0 !important;
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        @media (width > 640px) {
            .question-table .question-table__row-header-container {
                background: var(--table-header-row-background-color);
                height: 100%;
                align-content: center;
            }

            .question-table .question-table__columns-header {
                background: var(--table-header-column-background-color);
            }
        }

        ${rowBg
        ? `
           .question-table__row-header-container .question-table__row-header .badge-hidden {
                background-color: transparent;
                border: 0;
            }
        `
        : ''}

        ${rowBgMobile &&
        `
        @media (width < 640px) {
            .question-table__rows > * {
                background-color: trasparent;
            }
        }
        `}

        ${columnBg
        ? `
           .question-table__column-title_isHidden {
                background: var(--table-header-column-background-color);
                border-radius: 0;
           }
            .question-table__column-title.question-table__column-title_isHidden .badge-hidden {
                border: 0;
                background: var(--table-header-column-background-color); 
            }
            
        `
        : ''}
        @media (max-width: 640px) {
            .question-table__columns-header {
                display: none;
            }

            .question-table .question-table__row-header-container {
                width: 100%;
            }

            .question-table__row-${id} {
                display: flex;
                font-size: 16px;
                color: #2f3747;
                align-items: center;
                justify-content: space-between;
            }

            .question-table__row-${id} {
                grid-gap: 0;
            }

            .question-table__row-${id} > div {
                margin-top: 8px;
            }

            .question-table__row-option {
                background: var(--table-mobile-option-background-color);
                border-radius: var(--table-mobile-option-border-radius);
                flex-basis: 48px;
                height: 44px;
                margin-left: 10px;
            }

            .question-table__row-option:hover {
                background: var(--table-mobile-option-background-color-hover);
            }

            .question-table__row-option.question-table__row-option_checked {
                background: var(--table-mobile-option-background-color-selected);
            }

            .question-table__row-option.question-table__row-option_checked:hover {
                background: var(--table-mobile-option-background-color-selected-hover);
            }

            .question__content_error_yes
                .question-table__row-${id}.question-table__row-${id}_noSelection
                .question-table__row-option {
                background: var(--table-mobile-option-error-background-color);
            }

            .question__content_error_yes
                .question-table__row-${id}.question-table__row-${id}_noSelection
                .question-table__row-option:hover {
                background: var(--table-mobile-option-error-background-color-hover);
            }

            .question-table__row-${id} img {
                width: 80px;
                height: 45px;
                margin-top: 0;
                border-radius: 7px;
                padding: 3px;
            }

            .question-table__rows .choice__option {
                width: 48px;
                height: 40px;
            }

            .question-table__row-header {
                flex-basis: 100%;
                flex-direction: column;
            }

            .question-table__row-container {
                background: var(--table-mobile-header-column-background-color);
                display: flex;
                flex-basis: 100%;
                height: auto;
            }

            div.question-table__row-header
                > .question-table__image-container
                > .question-table__column-image {
                height: 112px;
                width: 100%;
            }

            .question-table__row-1 > div:first-of-type {
                display: block;
            }

            .question-table__row-header-text {
                background: var(--table-mobile-header-row-background-color);
                border-radius: 12px;
                margin-top: 28px;
                margin-bottom: 5px;
                word-break: break-word;
            }

            .question-table__row-header-text .markdown {
                padding: 8px 12px;
            }

            div.question-table__row-header > div.question-table__image-container {
                margin-top: 12px;
            }

            div.question-table__row-header > div.question-table__row-header-text {
                margin-top: 15px;
            }

            .question-table__column-title-mobile {
                flex: 1 0 0px;
                text-align: center;
                min-width: 0;
                word-wrap: break-word;
                display: flex;
                justify-content: end;
                flex-direction: row;
            }

            .question-table__rows {
                grid-gap: 0px;
            }

            .question-table__label-text {
                text-align: left;
                padding-right: 5px;
            }

            .question-table__image-container_zoomable:hover::after {
                width: 0px;
                height: 0px;
            }

            .question-table__row-${id}_isHidden {
                margin-top: 20px;
            }
        }
    `;
}
exports.default = getQuestionTableStyles;
