"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// eslint-disable-next-line complexity
function compileTableStyleString(branding) {
    const { table = {}, mobile: { table: mobileTable = {} } = {} } = branding;
    const { border: { style: borderStyle, color: borderColor, width: borderWidth } = {}, header: { row: { bg: headerRowBg } = {}, column: { bg: headerColumnBg } = {} } = {}, option: { default: { bg: optionDefaultBg } = {}, error: { bg: optionErrorBg, bgHover: optionErrorBgHover } = {}, hover: { bg: optionHoverBg } = {}, radius: optionRadius, selected: { bg: optionSelectedBg, bgHover: optionSelectedBgHover } = {} } = {} } = table;
    const { border: { style: mobileBorderStyle, color: mobileBorderColor, width: mobileBorderWidth } = {}, header: { row: { bg: mobileHeaderRowBg } = {}, column: { bg: mobileHeaderColumnBg } = {} } = {}, option: { default: { bg: mobileOptionDefaultBg } = {}, error: { bg: mobileOptionErrorBg, bgHover: mobileOptionErrorBgHover } = {}, hover: { bg: mobileOptionHoverBg } = {}, radius: mobileOptionRadius, selected: { bg: mobileOptionSelectedBg, bgHover: mobileOptionSelectedBgHover } = {} } = {} } = mobileTable;
    return `
        {
            ${borderStyle ? `--table-border-style: ${borderStyle};` : ''}
            ${borderColor ? `--table-border-color: ${borderColor};` : ''}
            ${borderWidth ? `--table-border-width: ${borderWidth};` : ''}
            ${headerRowBg ? `--table-header-row-background-color: ${headerRowBg};` : ''}
            ${headerColumnBg ? `--table-header-column-background-color: ${headerColumnBg};` : ''}
            ${optionDefaultBg ? `--table-option-background-color: ${optionDefaultBg};` : ''}
            ${optionErrorBg ? `--table-option-error-background-color: ${optionErrorBg};` : ''}
            ${optionErrorBgHover
        ? `--table-option-error-background-color-hover: ${optionErrorBgHover};`
        : ''}
            ${optionHoverBg ? `--table-option-background-color-hover: ${optionHoverBg};` : ''}
            ${optionRadius ? `--table-option-border-radius: ${optionRadius};` : ''}
            ${optionSelectedBg ? `--table-option-background-color-selected: ${optionSelectedBg};` : ''}
            ${optionSelectedBgHover
        ? `--table-option-background-color-selected-hover: ${optionSelectedBgHover};`
        : ''}
            ${mobileBorderStyle ? `--table-mobile-border-style: ${mobileBorderStyle};` : ''}
            ${mobileBorderColor ? `--table-mobile-border-color: ${mobileBorderColor};` : ''}
            ${mobileBorderWidth ? `--table-mobile-border-width: ${mobileBorderWidth};` : ''}
            ${mobileHeaderRowBg
        ? `--table-mobile-header-row-background-color: ${mobileHeaderRowBg};`
        : ''}
            ${mobileHeaderColumnBg
        ? `--table-mobile-header-column-background-color: ${mobileHeaderColumnBg};`
        : ''}
            ${mobileOptionDefaultBg
        ? `--table-mobile-option-background-color: ${mobileOptionDefaultBg};`
        : ''}
            ${mobileOptionErrorBg
        ? `--table-mobile-option-error-background-color: ${mobileOptionErrorBg};`
        : ''}
            ${mobileOptionErrorBgHover
        ? `--table-mobile-option-error-background-color-hover: ${mobileOptionErrorBgHover};`
        : ''}
            ${mobileOptionHoverBg
        ? `--table-mobile-option-background-color-hover: ${mobileOptionHoverBg};`
        : ''}
            ${mobileOptionRadius ? `--table-mobile-option-border-radius: ${mobileOptionRadius};` : ''}
            ${mobileOptionSelectedBg
        ? `--table-mobile-option-background-color-selected: ${mobileOptionSelectedBg};`
        : ''}
            ${mobileOptionSelectedBgHover
        ? `--table-mobile-option-background-color-selected-hover: ${mobileOptionSelectedBgHover};`
        : ''}
        }
    `;
}
exports.default = compileTableStyleString;
