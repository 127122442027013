"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DARK_THEME_PAGE_BG_COLOR = exports.LIGHT_THEME_PAGE_BG_COLOR = void 0;
const react_1 = require("@emotion/react");
exports.LIGHT_THEME_PAGE_BG_COLOR = '#fff';
exports.DARK_THEME_PAGE_BG_COLOR = '#323135';
exports.default = (0, react_1.css) `
    :root {
        --app-bg: #eff2f7;
        --question-caption-color: #777;
        --input-border-color-hovered: rgba(0, 0, 0, 0.3);
        --input-border-color: rgba(0, 0, 0, 0.1);
        --input-control-placeholder-color: #999;
        --long-text-textarea-scrollbar-track-color: #fff;
        --long-text-textarea-scrollbar-thumb-color: rgba(0, 0, 0, 0.1);
        --page-bg: ${exports.LIGHT_THEME_PAGE_BG_COLOR};
        --feedback-demo-bg: #eff2f7;
        --choice-option-label-disabled-color: #777;
        --button-normal-color: #000;
        --button-normal-color-checked: var(--view-default-text-action-color);
        --emotion-label-text-color: #2f3747;
        --emotion-label-text-selected-color: var(--emotion-label-text-color);
        --emotion-label-text-hover-color: var(--emotion-label-text-color);
        --input-control-bg-color: transparent;
        scroll-behavior: smooth;

        .Theme_root_default {
            --calendar-view-default-cell-border-width-today: 1px;
            --calendar-view-default-cell-border-width-selected: 0px;
            --calendar-view-default-cell-border-width-base: 0px;
            --calendar-view-default-cell-border-color-base: #ffffff;
            --calendar-view-default-cell-border-color-base-hovered: #f2f2f2;
            --calendar-view-default-cell-border-color-base-pressed: #e5e5e5;

            --calendar-view-default-cell-typo-color-selected-base: #000000;
        }
    }

    .pythia-surveys-root[data-theme='dark'] {
        --view-default-control-background: rgba(255, 255, 255, 0.06);
        --view-default-control-color-border: #ccc;
        --scale-option-bg: rgba(255, 255, 255, 0.06);
        --scale-option-hover-bg: rgba(255, 255, 255, 0.18);
        --scale-labels-color: rgba(255, 255, 255, 0.48);
        --choice-option-label-disabled-color: #fff;
        --choice-option-text-color: #ffffff;
        --question-caption-color: rgba(255, 255, 255, 0.69);
        --question-label-font-color: #ffffff;
        --input-border-color-hovered: rgba(255, 255, 255, 0.1);
        --input-border-color: rgba(255, 255, 255, 0.1);
        --input-control-placeholder-color: rgba(255, 255, 255, 0.4);
        --long-text-textarea-scrollbar-track-color: #323135;
        --long-text-textarea-scrollbar-thumb-color: rgba(255, 255, 255, 0.2);
        --input-control-bg-color: transparent;
        --input-control-color: var(--text-color);

        --app-bg: rgba(27, 32, 43, 1);
        --page-bg: ${exports.DARK_THEME_PAGE_BG_COLOR};
        --feedback-demo-bg: rgba(27, 32, 43, 1);
        --button-normal-color: #fff;

        --button-normal-bg: rgba(255, 255, 255, 0.06);
        --button-normal-hover-bg: rgba(255, 255, 255, 0.18);
        --button-normal-selected-bg: rgba(255, 255, 255, 0.18);
        --button-normal-selected-hover-bg: rgba(255, 255, 255, 0.24);

        --emotion-label-text-color: #fff;
        --emotion-label-text-selected-color: var(--emotion-label-text-color);
        --emotion-label-text-hover-color: var(--emotion-label-text-color);

        --table-border-color: white;
        --table-header-row-background-color: ${exports.DARK_THEME_PAGE_BG_COLOR};
        --table-header-column-background-color: ${exports.DARK_THEME_PAGE_BG_COLOR};
        --table-mobile-border-color: white;
        --table-mobile-header-row-background-color: rgb(76, 74, 75);

        --suggest-field-background-color: ${exports.DARK_THEME_PAGE_BG_COLOR};
        --suggest-field-shadow-light: 0 4px 12px 0 rgba(0, 0, 0, 0.4) 0 20px 11px -14px rgba(0, 0, 0, 0.3);
    }

    .app_theme_default {
        background: var(--app-bg);

        & .page {
            margin: var(--page-margin);
            height: auto;

            border: none;
            border-radius: 12px;
            -webkit-background-clip: padding-box;
        }

        @media (min-width: 550px) {
            & {
                background: var(--app-bg);
            }

            & .app__body {
                display: table-row;
            }

            & .app__footer {
                display: table-row;
            }

            /* Делаем страницы фиксированного размера */
            & .page {
                width: 54em;
                margin: var(--page-margin-mobile);
            }
        }

        @media (min-width: 860px) {
            & .page {
                width: 86em;
                max-width: 860px;
            }
        }
    }
`;
