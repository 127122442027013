"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
function getQuestionGeoStyles(scrollView) {
    return (0, react_1.css) `
        .geo__suggest-container {
            position: relative;
            z-index: 101;
        }

        .geo__suggest-cover {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .geo__suggest {
            margin-top: var(--suggest-field-margin-top);
            padding-top: var(--suggest-field-padding-top);
            padding-bottom: var(--suggest-field-padding-bottom);
            padding-right: var(--suggest-field-padding-right);
            padding-left: var(--suggest-field-padding-left);
            border-radius: var(--suggest-field-border-radius);
            border: 1px solid rgba(15, 15, 15, 0.1);
            box-sizing: border-box;
            box-shadow: var(--suggest-field-shadow);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: var(--suggest-field-background-color);
            z-index: 10;
        }

        .geo__suggest-wrapper {
            max-height: var(--suggest-field-max-height);
            overflow-y: var(--suggest-field-overflow-y);
        }

        @supports selector(::-webkit-scrollbar) {
            .geo__suggest-wrapper::-webkit-scrollbar {
                width: ${scrollView === 'thin' ? '4px' : '8px'};
            }

            .geo__suggest-wrapper::-webkit-scrollbar-thumb {
                background: rgba(0, 0, 0, 0.2);
                border-radius: 5px;
            }

            .geo__suggest-wrapper::-webkit-scrollbar-track {
                background-color: transparent;
            }

            .geo__suggest-wrapper::-webkit-scrollbar-thumb:hover {
                background: rgba(0, 0, 0, 0.4);
            }
        }

        @supports not selector(::-webkit-scrollbar) {
            .geo__suggest-wrapper {
                ${scrollView === 'thin' && 'scrollbar-width: thin'};
                scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
            }
        }

        .geo__item {
            padding: 0.6em 1em;

            font-size: 1.3em;
            cursor: pointer;
            white-space: nowrap;
            border-radius: var(--suggest-field-item-border-radius);
        }

        .geo__item-text {
            margin-left: var(--suggest-field-item-margin-left);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .geo__item:hover {
            background-color: var(--suggest-field-item-hover-background-color);
        }

        .geo__item_withFocus {
            outline: 2px solid var(--choice-option-control-focus-visible-border-color);
            outline-offset: -1px;
        }

        .geo__hidden {
            position: absolute;
            left: -9999px;
        }
    `;
}
exports.default = getQuestionGeoStyles;
