"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
exports.default = (0, react_1.css) `
    .pythia-surveys-root[data-theme='light'] {
        --choice-option-control-bg-color: #ecebea;
        --scale-option-bg: #ecebea;
        --scale-option-hover-bg: #d1d1d1;
        --scale-labels-color: #777;
        --question-caption-color: #777;

        --input-border-color-hovered: rgba(0, 0, 0, 0.3);
        --input-border-color: rgba(0, 0, 0, 0.1);
        --input-border-color-active: rgba(0, 0, 0, 0.1);
        --input-control-placeholder-color: #999;
        --input-control-bg-color: #fff;
        --app-bg: #fff;
        --page-bg: #fff;
    }

    .pythia-surveys-root[data-theme='dark'] {
        --view-default-control-checked-background: #FDDE55;
        --choice-option-control-bg-color: rgba(255, 255, 255, 0.06);
        --scale-option-bg: rgba(255, 255, 255, 0.06);
        --scale-option-hover-bg: rgba(255, 255, 255, 0.18);
        --scale-labels-color: rgba(255, 255, 255, 0.48);
        --view-default-control-color-border: rgba(255, 255, 255, 0.27);
        --choice-option-label-disabled-color: #fff;
        --question-caption-color: rgba(255, 255, 255, 0.69);
        
        --input-border-width: 0;
        --input-border-bottom-width: var(--input-border-weight);
        --input-border-color-hovered: rgba(255, 255, 255, 0.1);
        --input-border-color: rgba(255, 255, 255, 0.1);
        --input-border-color-active: rgba(255, 255, 255, 0.1);
        --input-control-placeholder-color: rgba(255, 255, 255, 0.4);
        --input-control-bg-color: #323135;
        --app-bg: #323135;
        --page-bg: #323135;
        --button-action-bg: #FDDE55;
        --button-action-hover-bg: #ffe055;
        --button-disabled-bg: rgba(255, 255, 255, 0.12);
        --button-disabled-text-opacity: 1;
    }

    :root {
        --page-height: auto;
        --page-safari-in-telegram-padding-bottom: 18px;

        --page-padding-mobile-top: 2em;
        --page-padding-mobile-left: 2em;
        --page-padding-mobile-bottom: 2em;
        --page-padding-mobile-right: 2em;

        --button-font-weight: 500;
        --button-focus-visible-border-color: #0060D0;
        --question-label-font-size: 16px;
        --question-label-font-weight: 500;
        --question-label-line-height: 20px;
        --question-label-margin-bottom: 13.5px;
        --question-label-margin-bottom-with-caption: -1.8px;
        --question-caption-font-size: 1.3em;
        --question-caption-margin-bottom: 1.9em;
        --question-caption-margin-top: 0.8em;
        --question-caption-padding-left: 4px;

        // Choice styles

        --choice-option-padding-left: 4px;
        --choice-option-margin-top: 1.2em;
        --choice-option-label-font-size: 1.5em;
        --choice-option-label-font-weight: 400;
        --choice-option-control-width: 1.8em;
        --choice-option-control-focus-visible-border-color: #0060D0;
        --choice-option-padding-top: 0.3em;
        --multi-choice-option-control-border-radius: 4px;
        --choice-option-checkmark-left: 6px;
        --choice-option-checkmark-top: 10%;
        --view-default-control-border: none;
        --choice-option-margin-inline-end: 0;
        --choice-option-checkmark-weight: 2px;
        
        // Emotion styles

        --emotion-focus-visible-border-color: #0060D0;

        // Rating styles

        --rating-focus-visible-border-color: #0060D0;    

        // Scale styles

        --scale-labels-font-size: 14px;
        --scale-labels-line-height: 18px;
        --scale-labels-margin-bottom: 12px;
        --scale-labels-margin-top: 2em;

        --scale-mobile-few-options-font-size: 5.2vw;
        --scale-mobile-many-options-font-size: 3.963vw;

        --scale-option-border-radius: 50%;
        --scale-option-border-width: 0;
        --scale-option-flex: 0;
        --scale-option-font-size: 1.09em;
        --scale-option-focus-visible-border-color: #0060D0;
        --scale-option-hover-bg: #d1d1d1;
        --scale-option-line-height: 2.2em;
        --scale-option-width: 2.2em;
        --scale-options-padding-inline: 9px;

        --scale-many-options-padding-inline: 0px;

        --scale-mobile-many-options-label-width: 1.7em;
        --scale-mobile-many-options-label-line-height: 1.7em;
        --scale-mobile-many-options-margin-bottom: 9px;

        --scale-options-max-width: 470px;
        --scale-options-position: center;

        // Long text styles

        --long-text-textarea-font-size: 15px;
        --long-text-textarea-line-height: 20px;
        --long-text-textarea-max-height: 200px;

        --input-border-width: 0;
        --input-border-bottom-width: var(--input-border-weight);
        --input-border-bottom-margin: 0 4px 0 0;
        --input-focus-visible-border-color: #0060D0;
        --input-padding: 0 2px;

        // Errors

        --error-margin: 1em 0 0 0;
        --scroll-margin-right: -12px;
        --scroll-padding-right: 16px;

        // Next Button

        --button-r-font-size: 13px;
        --button-action-min-width: 100%;
        --button-action-border-radius: 10px;
        --button-r-height: 36px;

        --button-action-bg: rgba(255, 204, 0, 1);
        --button-action-margin-top: 2em;

        // Asterix

        --view-required-asterisk: transparent;

        // Final Page

        --page-final-font-size: 18px;
        --page-final-font-weight: 500;
        --page-final-line-height: 24px;
        --page-final-padding: 100px;

        // Markdown

        --markdown-font-size: 16px;
        --markdown-line-height: 20px;
        --markdown-font-weight: 500;

        --markdown-h1-font-size: 16px;
        --markdown-h1-line-height: 20px;
        --markdown-h1-font-weight: 500;

        --markdown-h2-font-size: 15px;
        --markdown-h2-line-height: 20px;
        --markdown-h2-font-weight: 500;

        --markdown-line-height: 1.2;

        --widget-font-size: var(--markdown-h2-font-size);
        --widget-line-height: var(--markdown-h2-line-height);
        --widget-font-weight: var(--markdown-h2-font-weight);
    }
`;
