"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSuggestions = void 0;
/* eslint-disable camelcase */
const react_1 = require("react");
// eslint-disable-next-line import/prefer-default-export
function useSuggestions(accuracy = 'locality', lang = 'ru', suggestCount = 5) {
    const [suggestItems, setSuggestItems] = (0, react_1.useState)([]);
    const fetchSuggestions = (0, react_1.useCallback)(
    // eslint-disable-next-line max-statements
    (part) => __awaiter(this, void 0, void 0, function* () {
        if (!part.trim()) {
            setSuggestItems([]);
            // eslint-disable-next-line padding-line-between-statements
            return;
        }
        const urlParams = {
            client_id: 'surveys',
            fullpath: '0',
            results: suggestCount.toString(),
            type: 'tp',
            short_names: '0',
            bases: accuracy,
            part,
            v: '9',
            outformat: 'json',
            cut_country_from_fullpath: '1',
            lang,
            keep_spec_ops_regions: '0'
        };
        const url = new URL('https://suggest-maps.yandex.ru/suggest-geo');
        const searchParams = new URLSearchParams(urlParams);
        url.search = searchParams.toString();
        try {
            const response = yield fetch(url);
            const json = yield response.json();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const items = json.results.map((item) => ({
                displayName: item.text,
                value: item.text
            }));
            setSuggestItems(items);
        }
        catch (e) {
            console.error('Failed to fetch suggestions', e);
            setSuggestItems([]);
        }
    }), [accuracy, lang]);
    const clearSuggestions = () => setSuggestItems([]);
    return { suggestItems, fetchSuggestions, clearSuggestions };
}
exports.useSuggestions = useSuggestions;
