"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("@emotion/react");
function getQuestionTableBorderStyles(withBorder, withBorderMobile) {
    return (0, react_1.css) `
        ${withBorder &&
        `
        @media (width > 640px) {
            .question-table {
                border-width: var(--table-border-width);
                border-color: var(--table-border-color);
                border-style: var(--table-border-style);
            }

            .question-table__columns-header {
                gap: unset;
                padding: unset;
            }

            .question-table__column-title {
                border-left-width: var(--table-border-width);
                border-left-color: var(--table-border-color);
                border-left-style: var(--table-border-style);
            }

            .question-table__row-container {
                border-left-width: var(--table-border-width);
                border-left-color: var(--table-border-color);
                border-left-style: var(--table-border-style);
            }

            .question-table__rows {
                grid-gap: unset;
            }

            .question-table__rows > * {
                border-top-width: var(--table-border-width);
                border-top-color: var(--table-border-color);
                border-top-style: var(--table-border-style);
                gap: 0px;

                /* Фикс для показа скрытых вопросов в таблице */
                padding: 0;
                border-radius: 0;
                margin-inline: 0;
            }

            .question-table__column-title.question-table__column-title_isHidden {
                border-radius: 0;
            }
        }
        `}

        ${withBorderMobile &&
        `
        @media (width <= 640px) {
            .question-table__rows > * {
                border-top-width: var(--table-mobile-border-width);
                border-top-color: var(--table-mobile-border-color);
                border-top-style: var(--table-mobile-border-style);

                gap: 0px;

                /* Фикс для показа скрытых вопросов в таблице */
                margin-top: 0;
                padding: 0;
                border-radius: 0;
                margin-inline: 0;
            }
            .question-table__rows > *:first-child {
                border-top: 0;
            }

            .question-table__rows > * > div {
                margin-top: 0;
            }

            .question-table {
                border-width: var(--table-mobile-border-width);
                border-color: var(--table-mobile-border-color);
                border-style: var(--table-mobile-border-style);
            }

            .question-table__row-container {
                border-top-width: var(--table-mobile-border-width);
                border-top-color: var(--table-mobile-border-color);
                border-top-style: var(--table-mobile-border-style);
            }

            .question-table__column-title-mobile {
                align-items: center;
            }

            div.question-table__row-header > div.question-table__row-header-text {
                margin-top: 0;
                margin-bottom: 0;
                border-radius: 0;
            }
        }
        `}
    `;
}
exports.default = getQuestionTableBorderStyles;
